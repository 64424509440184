import {
  useFormFieldGroup,
  useSelectField,
  useField,
  useFormSection,
  useTextField
} from "@/components/FormBuilder/Helpers";
import {
  limitDed1OptionsCompany,
  limitDed1OptionsCompanyGreaterThan4,
  occupancyOptions,
  yearOptions,
  yearBuiltOptions,
  yesNoOptions,
  billingPlanOptions,
  waterLimitOptions,
  limitedSlabFoundationOptions
} from "@/helpers/selectOptions";
import sharedUnderwritingQuestions from "@/forms/shared/quote.shared_underwriter_questions";
import { ICompany } from "../../store/modules/companies/types";
import { onlySeacoastSelected } from "../utils";
import { isPoolInApprovedFence } from "../utils/quotes";
import { isValidEffectiveDate } from "@/helpers/validateEffectiveDate";
import { ICompaniesData, roofMaterialOptions } from "./quote.home_owner";

export const paymentPlanOptions = [
  { label: "Bill Insured", value: "1" },
  { label: "Bill Mortgagee(in full only)", value: "2" }
];

export const propertyUsageOptions = [
  { label: "Primary", value: "0" },
  { label: "Secondary", value: "1" },
  { label: "Seasonal", value: "2" }
];

export const structureTypeOptions = [
  { label: "Brick", value: "M" },
  { label: "Brick Veneer", value: "B" },
  { label: "Stucco", value: "A" },
  { label: "Frame", value: "F" }
];

export const dwellingTypeOptions = [
  { label: "Dwelling", value: "0" },
  { label: "Townhouse", value: "1" },
  { label: "Duplex", value: "2" }
];

export function getDwellingLimitedDedOptions(companies: ICompany[]) {
  let dwellingFireLimitDed1Options = limitDed1OptionsCompany;
  // iterate through the companies and check if mCompany is greater than or equal to 4
  const mCompanyGreaterThan4 =
    companies &&
    companies.length > 0 &&
    companies.filter(company => company.mCompany >= 4).length > 0
      ? true
      : false;
  if (mCompanyGreaterThan4) {
    // check if limitDed1OptionsCompanyGreaterThan4 does not exist in dwellingFireLimitDed1Options
    if (
      limitDed1OptionsCompanyGreaterThan4.every(
        option =>
          !dwellingFireLimitDed1Options.some(
            (homeOwnerLimitDed1Option: any) =>
              homeOwnerLimitDed1Option.value === option.value
          )
      )
    ) {
      dwellingFireLimitDed1Options = [
        ...limitDed1OptionsCompanyGreaterThan4,
        ...dwellingFireLimitDed1Options
      ];
    }
  }

  return dwellingFireLimitDed1Options;
}

export const underWriterApprovalSchema = [
  ...useFormFieldGroup(
    [
      useField(
        {
          type: "checkbox",
          label: "Has Underwriter approved all questionable answers?",
          key: "hasUnderwriterApproved",
          required: true,
          conditions: {
            and: [
              {
                field: "needsUnderwriterApproval",
                value: true,
                operator: "equals"
              }
            ]
          }
        },
        ["mt-8"]
      ),
      useTextField(
        {
          label: "Approval Code",
          key: "approvalCode",
          required: true,
          conditions: {
            and: [
              {
                field: "needsUnderwriterApproval",
                value: true,
                operator: "equals"
              },
              {
                field: "hasUnderwriterApproved",
                value: true,
                operator: "equals"
              }
            ]
          }
        },
        ["mt-8"]
      )
    ],
    { layout: "2-col" }
  )
];

export default function(data: ICompaniesData) {
  const { companies, companyNumbers, user, policyType, createdOn } = data;
  const dwellingFireLimitDed1Options = getDwellingLimitedDedOptions(companies!);

  const billingOptions = billingPlanOptions;

  if (onlySeacoastSelected(companyNumbers)) {
    billingOptions.pop();
  }

  const dwellingFireForm = [
    ...useFormFieldGroup(
      [
        useField({
          key: "effectiveDate",
          label: "Effective Date",
          required: true,
          type: "date",
          pickerOptions: {
            disabledDate: (fieldValue: Date) =>
              !isValidEffectiveDate(fieldValue, createdOn, user)
          }
        }),
        useSelectField({
          key: "paymentPlan",
          label: "Payment Plan",
          options: paymentPlanOptions,
          required: true
        }),
        useSelectField({
          key: "billing",
          label: "Billing Plan",
          options: billingOptions,
          required: true,
          conditions: {
            and: [
              {
                field: "paymentPlan",
                value: "1",
                operator: "equals"
              }
            ]
          }
        })
      ],
      { layout: "3-col" }
    ),
    ...useFormSection(
      [
        ...useFormSection(
          [
            ...useFormFieldGroup(
              [
                useSelectField({
                  key: "useAge",
                  label: "Property Usage",
                  options: propertyUsageOptions,
                  required: true
                }),
                useSelectField({
                  key: "dwellType",
                  label: "Dwelling Type",
                  options: dwellingTypeOptions,
                  required: true
                }),
                useSelectField({
                  key: "structureType",
                  label: "Building Structure Type",
                  options: structureTypeOptions,
                  required: true
                })
              ],
              { layout: "3-col" }
            )
          ],
          {
            title: "Property Usage & Construction"
          }
        ),
        ...useFormSection(
          [
            ...useFormFieldGroup(
              [
                useSelectField({
                  key: "limitDed1",
                  label: "EC Ded",
                  options: dwellingFireLimitDed1Options,
                  required: true
                }),
                useSelectField({
                  key: "roofMaterial",
                  label: "Roof Material",
                  options: roofMaterialOptions(data),
                  required: true,
                  information: {
                    title: "Reminder",
                    body: `As a reminder Insurors requires 100% composition shingle <br>
                      over all living areas`
                  },
                  conditions: {
                    or: [
                      {
                        field: "shouldAddRoofMaterial",
                        value: true,
                        operator: "equals"
                      }
                    ]
                  }
                })
              ],
              { layout: "4-col" }
            )
          ],
          {
            title: "Limits and Deductible Modifiers",
            description: ""
          }
        ),
        ...useFormSection(
          [
            ...useFormFieldGroup(
              [
                useSelectField({
                  key: "yearBuild",
                  label: "Year Built",
                  options: yearBuiltOptions,
                  required: true,
                  filterable: true,
                  allowCreate: false,
                  allowFirstOption: true,
                  conditions: {
                    and: [
                      {
                        operator: "hasValue",
                        field: "companyNumbers",
                        value: true
                      }
                    ]
                  },
                  validations: [
                    {
                      method: "custom",
                      customValidator: (value: any) => {
                        if (!value) return true;
                        return !isNaN(value) && `${value}`.length === 4;
                      },
                      error: "Must be a year!"
                    }
                  ]
                }),
                useSelectField({
                  key: "yearOfRoof",
                  label: "Roof Year",
                  options: yearOptions,
                  required: true,
                  filterable: true,
                  allowFirstOption: true,
                  conditions: {
                    and: [
                      {
                        operator: "hasValue",
                        field: "companyNumbers",
                        value: true
                      }
                    ]
                  }
                }),
                useTextField({
                  label: "Square Feet",
                  key: "squareFeet",
                  required: true,
                  validations: [
                    { method: "integer", error: "Please enter a number" }
                  ]
                })
              ],
              { layout: "3-col" }
            ),
            useField({
              key: "amountDWPCompanyOverrides",
              label: "Adjustments to building amount per company and form type",
              required: false,
              type: "company-overrides-df"
            }),
            ...useFormFieldGroup(
              [
                useSelectField(
                  {
                    key: "occupancy",
                    label: "Occupancy",
                    options: occupancyOptions,
                    required: true,
                    conditions: {
                      and: [
                        {
                          field: "squareFeet",
                          value: "",
                          operator: "not"
                        }
                      ]
                    }
                  },
                  ["mt-6"]
                )
              ],
              { layout: "3-col" }
            ),
            useField(
              {
                type: "radio_button_group",
                preamble: "Number of Non-Weather or Non-Appliance Claims",
                key: "numberOfNonWeatherClaims",
                options: [0, 1, 2, 3].map((op: number) => ({
                  value: op,
                  label: op.toString()
                })),
                required: true
              },
              ["mb-8"]
            ),
            useField(
              {
                type: "radio_button_group",
                preamble: "Trampoline on the premises?",
                key: "trampolineOnPremises",
                options: yesNoOptions,
                required: true,
                conditions: {
                  and: [
                    {
                      field: "hasColonialSeacoastSeaTerritoryOnly",
                      value: false,
                      operator: "equals"
                    }
                  ]
                }
              },
              ["mb-8"]
            ),
            useField({
              key: "trampolineApprovedFence",
              preamble: "Approved Fence?",
              type: "radio_button_group",
              options: yesNoOptions,
              conditions: {
                or: [
                  {
                    field: "trampolineOnPremises",
                    value: "Yes",
                    operator: "equals"
                  }
                ]
              },
              validations: [
                {
                  method: "custom",
                  error: "Risk Prohibited",
                  customValidator: (value: number) => {
                    return isPoolInApprovedFence(value, policyType);
                  }
                }
              ]
            })
            // useField(
            //   {
            //     type: "radio_button_group",
            //     preamble: "Is the dwelling on the seaward side of Hwy 59?",
            //     key: "dwellingOnSeawardIfOfHwy59",
            //     options: yesNoOptions,
            //     required: true,
            //     conditions: {
            //       and: [
            //         {
            //           field: "mCompanyLessThanOrEqual3",
            //           value: true,
            //           operator: "equals"
            //         }
            //       ]
            //     }
            //   },
            //   ["mb-8"]
            // )
          ],
          {
            title: "Dwelling Information"
          }
        )
      ],
      {
        title: "Property Information"
      }
    ),
    ...sharedUnderwritingQuestions(policyType, companyNumbers)
  ];

  dwellingFireForm.push(
    ...useFormSection(
      [
        ...useFormFieldGroup(
          [
            useSelectField({
              key: "waterLimit",
              label: "CDW-APW-0716 Limited Water Dmg (TDP 1&2)",
              required: true,
              conditions: {
                and: [
                  {
                    field: "companyNumbers",
                    value: 32,
                    operator: "includes"
                  },
                  {
                    field: "terrSeacoast",
                    value: false,
                    operator: "equals"
                  },
                  {
                    field: "includesRelevantEndorsements",
                    value: true,
                    operator: "equals"
                  }
                ]
              },
              options: waterLimitOptions
            }),
            useSelectField({
              key: "limitedSlabFoundation",
              label: "TDP-849 Limited Slab/Foundation (TDP 1&2)",
              required: true,
              conditions: {
                and: [
                  {
                    field: "companyNumbers",
                    value: 32,
                    operator: "includes"
                  },
                  {
                    field: "terrSeacoast",
                    value: false,
                    operator: "equals"
                  },
                  {
                    field: "includesRelevantEndorsements",
                    value: true,
                    operator: "equals"
                  }
                ]
              },
              options: limitedSlabFoundationOptions
            })
          ],
          { layout: "2-col" }
        )
      ],
      {
        title: ""
      }
    )
  );

  return dwellingFireForm;
}
